import React from 'react';
import {useTheme,useMediaQuery,Container, Typography, Stack,TextField, Card, CardContent,CardMedia, Button, Grid, ThemeProvider} from '@mui/material';
import HomePic from './images/cover.jpg'
import BG from './images/bg.avif'
import SearchIcon from "@mui/icons-material/Search";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SchoolIcon from '@mui/icons-material/School';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "./images/js.png";
import Image2 from "./images/finance.jpg";
import Image3 from "./images/program.jpg";
import ContactForm from './CONTACTFORM';
import Footer from './Footer';
import './LandingPage.css'
import Navbar from './Navbar';
import "./Navbar.css";
import Slide from './slider';
import Slideshow from './slider';
import Clients from './images/clients.png'
import Colorapps from '../configcss/color';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import JDC from './images/JDC.jpeg';
import JDC2 from './images/JDC2.jpeg';
import jdc3 from './images/jdc3.jpeg';
import Stickybar from '../socialmediabar';

const LandingPage = () => {
  //breakpoints
  const theme = useTheme();
  const isMatchsm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatchmd = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchlg = useMediaQuery(theme.breakpoints.down("lg"));
  //States
  const cards = [
    {
        icon:BrunchDiningIcon,
        name:"Food Program",
        desc:"A Google Docs scam that appears to be widespread began landing in Wednesday in what seemed to be a phishing attack."
    },
    {
        icon:SpaOutlinedIcon,
        name:"Disaster Relief",
        desc:"A Google Docs scam that appears to be widespread began landing in Wednesday in what seemed to be a phishing attack."
    },{
        icon:MedicationLiquidOutlinedIcon,
        name:"Health Infrastructure",
        desc:"A Google Docs scam that appears to be widespread began landing in Wednesday in what seemed to be a phishing attack."
    }
  ];
  const details = [{
    num:"20k",
    name:"Food Program"
  },{
    num:"124",
    name:"Disaster Relief"
  },{
    num:"600k",
    name:"Free Medicines"
  },{
    num:"15k",
    name:"Health Infrastructure"
  }]
  const trendingEvents = [
    {
      pic: JDC,
      message:"avaScript is a high-level programming language that is commonly used for creating interactive interface.",
      price: "PLAY",
    },
    {
      pic: JDC2,
       message:"Finance is a field that encompasses the management of money, investments, and financial resources. ",
      price: "PLAY",
    },
    {
      pic: jdc3,
      
      message:"Programming is the process of creating computer programs or software by using a specialized set of instructions.",
      price: "PLAY",
    },
    {
      pic: JDC,
      name: "JavaScript",
      message:"avaScript is a high-level programming language that is commonly used for creating interactive interface.",
      price: "PLAY",
    },
    {
      pic: jdc3,
      name: "Finance",
      message:"Finance is a field that encompasses the management of money, investments, and financial resources. ",
      price: "PLAY",
    },
  ];
  //Slider settings
  var settings = {
    dots: true,
    infinite: false,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  
  return (
    <>
    <Navbar/>
    <>
    <Stickybar/>
    <ThemeProvider theme={theme}>

    </ThemeProvider>
    </>
    <Container
        maxWidth="xxl"
      >
    <Slideshow/>
      
       
           <br />
           <br />
           <br />
           <br />
           
            {/* <Typography textAlign={isMatchsm?"center":null}>
            <TextField
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <SearchIcon
                            sx={{
                              color: "green",
                              marginRight: "8px"
                            }}
                          />
                        ),
                        style: {
                          borderRadius: "10px",
                          background: "#fff",
                          width:"150%",
                          paddingRight: "18px",
                          fontSize: "17px",
                        },
                        inputProps: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      placeholder="Search More"
                    /></Typography> */}
      
      <Container maxWidth="lg" sx={{mt:isMatchmd?null:"-80px"}}>
        <Stack direction={isMatchmd?"column":"row"} spacing={0}>
           {cards.map((card)=>{
            return(
                <>
                <Card sx={{py:"25px",px:"20px", borderRadius:'20px'}}>
                    <CardContent>
                        <Stack spacing={2}>
                            <Typography><card.icon sx={{fontSize:42,color:Colorapps.backgroundtheme}}/></Typography>
                            <Typography variant="h6"><b>{card.name}</b></Typography>
                            <Typography variant="body2" sx={{color:"#8a7b9b"}}>{card.desc}</Typography>
                        </Stack>
                    </CardContent>
                </Card>
                </>
            )
           })}
        </Stack>
      </Container>
      </Container>
       
      <Container maxWidth="md" sx={{my:"60px"}}>
<Typography textAlign="center" variant={isMatchsm?"h6":"h5"}><b><span style={{color:Colorapps.themesecondary}}>(LIHS)</span> has garnered significant trust from numerous generous donors.</b></Typography><br />
<img src={Clients} alt="Clients"  style={{width:'100%', justifyContent:'center', display:'flex'}}/>
<Typography textAlign="center" color="#8a7b9b">(LIHS) stands as a beacon of hope for those in need, offering vital assistance to impoverished communities worldwide. Through their dedicated efforts, LIHS provides sustenance to the hungry, ensuring that nutritious food reaches those who struggle to afford it. Beyond nourishment, LIHS extends a compassionate hand by providing shelter to the homeless, offering refuge in the form of sturdy and secure homes. With a commitment to improving lives and fostering resilience, </Typography>
      </Container>
      <div>
      
  <Typography
    sx={{
      backgroundColor: Colorapps.backgroundtheme,
      height: '50px',
      width: '180px',
      fontSize: '27px',
      fontWeight: '600',
      color: 'white',
      borderRadius: '50px',
      marginLeft: '15px',
      textAlign: 'center',
      animation: 'shimmer 1.5s infinite', // Applying the animation
      '@keyframes shimmer': { // Define keyframes
        '0%': {
          transform: 'scale(1)',
          opacity: 0.7,
          color: 'white',
        },
        '50%': {
          transform: 'scale(1.2)',
          opacity: 1,
          color: 'white', // change to any other color for shimmer effect
        },
        '100%': {
          transform: 'scale(1)',
          opacity: 0.7,
          color: 'white',
        },
      },
    }}
  >
    Milestones
  </Typography>
</div>
      <Container
        maxWidth="xxl"
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5) 50%), url(${BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundAttachment:"fixed",
        }}
      >
        <Stack direction={isMatchsm?"column":"row"} justifyContent="space-evenly" sx={{py:"80px"}}>
            {details.map((detail)=>{
                return(
                    <>
                    <Stack spacing={1} sx={{color:"#ffffff",mb:isMatchsm?"60px":null}}>
                 <Typography textAlign={isMatchsm?"center":null} variant="h3" ><b>{detail.num}</b></Typography>
                 <Typography variant="h5" textAlign={isMatchsm?"center":null} ><b>{detail.name}</b></Typography>
            </Stack>
                    </>
                )
            })}
        </Stack>
      </Container><br /><br /><br /><br />
      <Typography className='text-underlined' variant={isMatchsm?"h6":"h4"} textAlign="center"><b>Our Trending Videos</b></Typography><br /><br />
      <Container maxWidth="xl">
      <div>
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-lg-12 ">
                  <Slider {...settings}>
                    {trendingEvents.map((card) => {
                      return (
                        <>
                          <Card sx={{ borderRadius: "10px", mr: isMatchlg?"20px":"40px",mr:isMatchsm?"0px":"20px" }}>
                            <CardMedia
                              sx={{ height: "240px" }}
                              image={card.pic}
                              title="green iguana"
                            />
                            <CardContent sx={{ background: "#fff" }}>
                              <Typography
                                gutterBottom
                                color="white"
                                variant={isMatchsm ? "body1" : "h6"}
                                component="div"
                            
                              >
                                <span className="caraousalHeadings">
                                  <b>{card.name}</b>
                                </span>
                              </Typography>
                              <Typography color="#000">{card.message}</Typography>
                            </CardContent>
                            <CardContent
                              sx={{ background: "#fff", color: "#000" }}
                            >
                              <Button variant="contained" sx={{borderRadius:"40px",background:Colorapps.backgroundtheme,color:'white',px:"10px"}}>{card.price}</Button>
                            </CardContent>
                          </Card>
                        </>
                      );
                    })}
                  </Slider>
                  </div>
              </div>
               
            </div>
          </div>
          </Container>
          <br /><br /><br /><br/><br /><br />
          <ContactForm/>
          <Container maxWidth="xxl" sx={{background:"#f8faff"}}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item lg={6} sm={12}sx={{my:"60px",}}> 
                  <Stack spacing={1} sx={{color:"#000"}}>
                    <Typography variant="h5"><b>Join Our Volunteer Team</b></Typography>
                    <Typography color="#9d9d9f">Writers and stars of Veep have responded incredulously to the news an Australian politician required stitches after knocking himself unconscious .</Typography>
                  </Stack><br />
                  <Button variant="contained" sx={{borderRadius:"40px",background:Colorapps.backgroundtheme,color:"#fff",px:"10px"}}>Join Now</Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Footer/>
    </>
  )
}

export default LandingPage