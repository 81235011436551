import React from "react";
import "../src/main-css/main.css"; // Make sure to adjust the path if needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGoogle, faLinkedin, faYoutube, faSquareXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Stickybar() {
  return ( 
    <div className="icon-bar">
      <a href="#"  title="Facebook" className="facebook"><FontAwesomeIcon icon={faFacebook} /></a> 
      <a href="#" title="twitter" className="twitter"><FontAwesomeIcon icon={faSquareXTwitter} /></a> 
      <a href="#" title="instagram" className="google"><FontAwesomeIcon icon={faInstagram} /></a> 
      <a href="#" title="linkedin" className="linkedin"><FontAwesomeIcon icon={faLinkedin} /></a>
      <a href="#" title="youtube" className="youtube"><FontAwesomeIcon icon={faYoutube} /></a> 
    </div>
  );
}
