import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import Slideshow from './Components/slider';
import Login from './Components/Auth/Login';
import Dashboard from './adminview/pages/Dashboard/Dashboard'

function App() {
  return (
    <BrowserRouter>
       <Routes> 
         <Route path="/" element={<LandingPage />} />
         <Route path="/Slider" element={<Slideshow/>}/>
         {/* Login Routes */}
         <Route path="/Login" element={<Login/>}/>
         {/* Admin View */}
         <Route path="/Dashboard" element={<Dashboard/>}/>
       </Routes>
    </BrowserRouter>
  );
}

export default App;
