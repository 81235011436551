import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import LoupeIcon from '@mui/icons-material/Loupe';
import NoCrashSharpIcon from '@mui/icons-material/NoCrashSharp';
import '../Sidebar/sidebar.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import { appColors } from "../../config/colors";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import lihs from '../../../Components/images/lihs.png'

const Sidebars = (props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleButtonClick = (sidelink) => {
    switch (sidelink) {
      case 'homepage':
        navigate('/');
        break;

      case 'dashboard':
        navigate('/Dashboard');
        break; 
      
      case 'addvehicle':
        navigate('/Addvehcile');
        break;

      case 'allvehicle':
        navigate('/allvehicle');
        break;

      default:
        // Handle other cases or provide a default route
        console.error('Unknown sidelink:', sidelink);
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh"}}>
        
      <Sidebar
        className={`app ${isMobile ? "mobile-sidebar" : ""}`}
        collapsed={isMobile && !mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
      >
      
        {/* Header of sidebar */}
        <Menu className=""> 
        <MenuItem className="menu1"  icon={
              isMobile ? (
                <MenuRoundedIcon onClick={handleDrawerToggle} />
              ) : (
                <>
              <img
  src={lihs}
  alt="Car"
  style={{
    position: 'absolute',
    width: '120px',
    height: 'auto',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }}
  onClick={() => handleButtonClick('homepage')} 
/>
                </>
              )
            }>
               
          </MenuItem>
        </Menu>
        <Menu >
        
          <MenuItem onClick={() => handleButtonClick('dashboard')} icon={<GridViewRoundedIcon />}> Dashboard </MenuItem>
          {/* <MenuItem icon={<DriveEtaIcon />}> Vehicles </MenuItem> */}
          <SubMenu  label="Home Page" icon={<DriveEtaIcon />}>
            <MenuItem onClick={() => handleButtonClick('')} icon={<LoupeIcon />}> Add Slider</MenuItem>
            <MenuItem onClick={() => handleButtonClick('')} icon={<NoCrashSharpIcon />}>Add Services</MenuItem>
          </SubMenu>
          <SubMenu label="Food Program" icon={<WalletRoundedIcon />}>
            <MenuItem icon={<AccountBalanceRoundedIcon />}>
              Add Food Program
            </MenuItem>
            {/* <MenuItem icon={<SavingsRoundedIcon />}>Savings Wallet</MenuItem> */}
          </SubMenu>
          {/* <MenuItem icon={<MonetizationOnRoundedIcon />}>Transactions</MenuItem>
          <SubMenu label="Settings" icon={<SettingsApplicationsRoundedIcon />}>
            <MenuItem icon={<AccountCircleRoundedIcon />}> Account </MenuItem>
            <MenuItem icon={<ShieldRoundedIcon />}> Privacy </MenuItem>
            <MenuItem icon={<NotificationsRoundedIcon />}>
              Notifications
            </MenuItem>
          </SubMenu> */}
          <MenuItem icon={<LogoutRoundedIcon />}> Logout </MenuItem>
        </Menu>
      </Sidebar>
      
    </div>
  );
};

export default Sidebars;
