import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
  Input,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import Fb from "./images/fb.png";
import Tweeter from "./images/tweeter.png";
import Insta from "./images/insta.png";
import Colorapps from "../configcss/color";

const Footer = () => {
  //CSS AND LISTS
  const theme = useTheme();
  const isMatchsm = useMediaQuery(theme.breakpoints.down("md"));
  const pages = ["About", "News", "Seekersgate Blog", "Sell on Seekersgate"];
  const socialMedia = [{
    icon:Fb,
    link:"#"
  },{
    icon:Tweeter,
    link:"#"
  },{
    icon:Insta,
    link:"#"
  }];
  return (
    <>
      <Container maxWidth="xxl" sx={{background:"#2a2c3b",py:"20px",color:"#fff"}}>
      <Typography marginLeft={'30px'} variant="h5"><b>LI<span style={{color:Colorapps.themeprimary}}>HS</span></b></Typography>
      <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{marginTop:"10px"}} >
      <Container maxWidth="sm">
               <Input placeholder=" Search"  className="underline" sx={{color:'white' ,  borderRadius:'9px', gap:'3', cursor: 'pointer'}}>
               </Input>
               <SearchIcon sx={{color:Colorapps.backgroundtheme, cursor:'pointer'}}/>
               </Container> 
      <Typography variant="h6" spacing={2} textAlign={"Right"}>
                  <b className="underline">Follow Us</b>
                   </Typography>   
                   </Stack>
                  <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{marginTop:"10px"}} >
              <Container maxWidth="sm" >
               <Typography
               sx={{fontFamily:'sans-serif', fontSize:'15px'}}
               >
              "Empowering Minds, Inspiring Futures – Where Learning Knows No Limits!"
               </Typography>
               </Container>
                  <Grid >
                    
                    <TwitterIcon sx={{"&:hover": {
      color:Colorapps.backgroundtheme, cursor:'pointer' 
    },}} onClick={''}/>
                    <InstagramIcon sx={{"&:hover": {
      color:Colorapps.backgroundtheme,cursor:'pointer' 
    },}} onClick={''}/>
                    <FacebookIcon sx={{"&:hover": {
      color:Colorapps.backgroundtheme,cursor:'pointer' 
    },}} onClick={''}/>
                    <WhatsAppIcon sx={{"&:hover": {
      color:Colorapps.backgroundtheme, cursor:'pointer' 
    },}} onClick={''}/>
                    
                   
                    </Grid>
                  </Stack>
                
                <Stack spacing={2}>
                
                </Stack>
                <Typography textAlign="center" marginTop={'20px'} fontSize={'12px'} variant={isMatchsm ? "subtitle2" : "subtitle1"}>
                    Copyright© 2024. All rights reserved.
                </Typography>
     </Container>
    </>
  );
};

export default Footer;
