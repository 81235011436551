const Colorapps =  { 

    textprimary : '#f7fcf9',
    textsecondary : '#050806',

    themeprimary : '#0da29a',
    themesecondary : '#065586',

    backgroundtheme : 'rgba(241, 86, 42)'


} 

export default Colorapps ;