import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import lihs from '../images/download.gif'
import { apiRoutes } from '../../Api/apiRoutes';
import {postRequest} from '../../Api';
import bglogin from '../images/Food.jpeg';
import { useNavigate } from "react-router-dom";
import Colorapps from '../../configcss/color';
import EmailIcon from '@mui/icons-material/Email';
import { InputAdornment } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import Stickybar from '../../socialmediabar';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        LIHS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


export default function Login() {
    const navigate = useNavigate();
const [email, setEmail] = React.useState();
const [password, setPassword] = React.useState();
const [msg, setMsg] = React.useState();


  async function handleLogin(event) {
    event.preventDefault();
    const onSuccess = (res) => {
        alert('login in')
        navigate('/Dashboard')
    }
    const onError =(res) => {
      alert('ERROR');
    } 
    await postRequest({email,password},apiRoutes.login,onSuccess, onError)  
  }

  return (
    <>
    
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bglogin})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              
            }}
          >
            <Avatar sx={{ m: 1, width:'90px', height:'80px', bgcolor: Colorapps.backgroundtheme }}>
            <img src={lihs} style={{width:'150px'}} alt="Lihs Image" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form"   sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{color:Colorapps.themeprimary}}  /> {/* Place the icon component here */}
                    </InputAdornment>
                     ),
                    }}
                >
               
                </TextField>
              <TextField
                margin="normal"
                required
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon sx={{color:Colorapps.themeprimary}} /> {/* Place the icon component here */}
                    </InputAdornment>
                     ),
                    }}
              />
             
              <Button
                
                fullWidth
                onClick={(e) => {
                    handleLogin(e)
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor:Colorapps.themesecondary, borderRadius:'20px', '&:hover': {
                 bgcolor:Colorapps.themesecondary
                } }}
              >
              <b>Login</b>
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </>
  );
}