import {
    AppBar,
    Grid,
    Stack,
    TextField,
    Toolbar,
    InputAdornment,
    styled,
    Typography,
    IconButton,
    Select,
    MenuItem,
    Menu,
    Button,
    FormControl,
    InputLabel,
    Container
  } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";
  import React, { useState } from "react";
  import { Link } from "react-router-dom";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import { useTheme, useMediaQuery, Divider } from "@mui/material";
  import DrawerComp from "./DrawerComp";
  import "./Navbar.css";
  import { useNavigate } from "react-router-dom";
  import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
  import lihs from './images/lihs.png'

import Colorapps from "../configcss/color";
  const Navbar = () => {
    // CSS AND STATES
    const theme = useTheme();
    const isMatchmd = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchlg = useMediaQuery(theme.breakpoints.down("lg"));
   
    const [openModal, setOpenModal] = useState(false);
    const Navigate = useNavigate();
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    // const gotologin = () => {
    //   Navigate('/Login')
    // }
    const [openModal1, setOpenModal1] = useState(false);
  
    const handleOpenModal1 = () => {
      setOpenModal1(true);
    };
  
    const handleCloseModal1 = () => {
      setOpenModal1(false);
    };
     const goToAdmin = () =>{
      Navigate('/Login')
     }
    return (
      <>
      
        <AppBar
          elevation={0}
          position="sticky"
          sx={{
            background: Colorapps.textprimary,
            color:Colorapps.textsecondary,
            pt: isMatchmd ? "4px" : "10px",
            overflowY: 'scroll',

          }}
        >
      

            <Container maxWidth="lg">
          <Toolbar>
            {isMatchlg ? (
              <>
                <DrawerComp />
              </>
            ) : (
              <Grid container>
                {isMatchmd ? null : (
                  <Grid item lg={12} md={6}>
                    <Stack direction="row" spacing={2}>
                     {/* <Typography className="title_nav" variant="h5" ml="-20px"><strong>SeekersGate</strong></Typography> */}
                 
                      {/* <TextField
                        variant="outlined"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <SearchIcon
                              sx={{
                                color: "white",
                                marginRight: "8px",
                              }}
                            />
                          ),
                          style: {
                            borderRadius: "5px",
                            background: "rgba(255, 255, 255, 0.12)",
                            paddingRight: "18px",
                            fontSize: "17px",
                          },
                          inputProps: {
                            style: {
                              color: "white",
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "white",
                          },
                        }}
                        placeholder="Search by team, artist, event, or venue"
                      /> */}
                    </Stack>
                  </Grid>
                )}
  
               
  
                <Grid lg={15} md={6} sm={1}>
                  
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center",justifyContent:"right", }}
                  >
                     <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-start' }}>
 
 <div style={{ marginLeft: '0px' }}>
   <img src={lihs} alt="lihs" className="logo" />
 </div>

<div>

</div>
</div>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.themesecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b>Home</b>
                      </Button>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.textsecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b>About</b>
                      </Button>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.textsecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b>Food Program</b>
                      </Button>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.textsecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b> Disaster Relief</b>
                      </Button>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.textsecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b>Free Medicines</b>
                      </Button>
                      
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          color: Colorapps.textsecondary,
                          textTransform: "capitalize",
                          '&:hover': {
                            backgroundColor:'rgba(241, 86, 42)',
                            color: 'white'
                          }
                        }}
                      >
                        <b>Health Care Infrastructure</b>
                      </Button>
                    <div
                      className="vl"
                      style={{ borderLeft: "1px solid #979497", height: "34px" }}
                    ></div>
  
                    <Button
                      onClick={goToAdmin}
                      variant="text"
                      size="large"
                      sx={{
                        color: Colorapps.textsecondary,
                        textTransform: "capitalize",
                      }}
                    >
                     <PersonOutlineIcon/> <b>Login</b>
                    </Button>
                   
                    {/* <Button
                        variant="text"
                        onClick={handleOpenModal1}
                        sx={{
                          color:Colorapps.textprimary,
                          textTransform: "capitalize",
                          background: "blue",
                          border: "1px solid white",
                          px: 3,
                        }}
                      >
                        <b>Login</b>
                      </Button> */}
                     <Button
  variant="text"
  onClick={goToAdmin}
  
  sx={{
    color: Colorapps.textprimary,
    textTransform: "capitalize",
    background: Colorapps.themesecondary,
    border: "1px solid black",
    px: 3,
    transition: "background-color 0.1s ease", // Add transition for smooth animation
    '&:hover': {
      backgroundColor: 'black' // Change background color on hover
    }
  }}
>
  <b>Contact Us</b>
</Button>

                      
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Toolbar>
          </Container>
          
        </AppBar>
      </>
    );
  };
  
  export default Navbar;
  