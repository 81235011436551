import React from "react";
import Sidebars from "../../Components/Sidebar/sidebar";
import { useTheme, ThemeProvider, Typography, useMediaQuery, Box, Container} from '@mui/material';
import '../../css/Dashboard.css'
import PersonIcon from '@mui/icons-material/Person';


const Dashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed


  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ display: 'flex' }}>
          <Sidebars />
          <div style={{  display: 'flex'}}>
          <Typography>
          <span className={`headertext ${isSmallScreen ? 'small-screen' : ''}`}>
                Dashboard
              </span>
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'70px', marginLeft:'-100px', rowGap: '5px' }}>
      <Box className={`adminboxveiw`} sx={{   width: '60%', marginTop: '15px' }}>
        <Container maxWidth='sm'>
          <Box style={{ backgroundColor: 'white', color: '#939596', textAlign: 'center', height: '100px', width: '250px', borderRadius:'20px' }}>
            <Typography style={{ fontSize:'18px', fontWeight:'400' ,marginTop: '12px', color: '#A098AE', fontFamily: 'sans-serif', borderRadius:'10px'}}>
            
            Total Clients 
            </Typography>
            <Typography variant='h5' style={{ color : 'black' , marginTop: '7px', fontSize:'30px', textAlign:'center' }}>
               <b>988</b>
              </Typography>
              <Typography  style={{ color : 'black' ,  fontSize:'10px', textAlign:'center' }}>
              +10% than last month
              </Typography>
            <div className="sidebar-container">
            <PersonIcon  />
            </div>
          </Box>
        </Container>
      </Box>
 <Box className={`adminboxveiw`} sx={{   width: '60%', marginTop: '15px' }}>
        <Container maxWidth='sm'>
          <Box style={{ backgroundColor: 'white', color: '#939596', textAlign: 'center', height: '100px', width: '250px', borderRadius:'20px' }}>
            <Typography style={{ fontSize:'18px', fontWeight:'400' ,marginTop: '12px', color: '#A098AE', fontFamily: 'sans-serif', borderRadius:'10px'}}>
            
            Total Clients 
            </Typography>
            <Typography variant='h5' style={{ color : 'black' , marginTop: '7px', fontSize:'30px', textAlign:'center' }}>
               <b>988</b>
              </Typography>
              <Typography  style={{ color : 'black' ,  fontSize:'10px', textAlign:'center' }}>
              +10% than last month
              </Typography>
            <div className="sidebar-container">
            <PersonIcon  />
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={`adminboxveiw`} sx={{   width: '60%', marginTop: '15px' }}>
        <Container maxWidth='sm'>
          <Box style={{ backgroundColor: 'white', color: '#939596', textAlign: 'center', height: '100px', width: '250px', borderRadius:'20px' }}>
            <Typography style={{ fontSize:'18px', fontWeight:'400' ,marginTop: '12px', color: '#A098AE', fontFamily: 'sans-serif', borderRadius:'10px'}}>
            
            Total Clients 
            </Typography>
            <Typography variant='h5' style={{ color : 'black' , marginTop: '7px', fontSize:'30px', textAlign:'center' }}>
               <b>988</b>
              </Typography>
              <Typography  style={{ color : 'black' ,  fontSize:'10px', textAlign:'center' }}>
              +10% than last month
              </Typography>
            <div className="sidebar-container">
            <PersonIcon  />
            </div>
          </Box>
        </Container>
      </Box>


    </div>
    </div>
          </div>
        
      </ThemeProvider>
    </>
  );
};

export default Dashboard;

