import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import HomePic from './images/cover.jpg';
import { Container, Stack, Typography } from '@mui/material';
import './LandingPage.css'
import Slider2 from './images/slider2.jpeg';
import futurn from './images/Future.jpeg'
import Food from './images/Food.jpeg'
import checking from './images/checking.jpeg'

const slideImages = [
  {
    url: futurn,
    caption: 'Slide 1',
    text: (
        <div>
        <Typography variant="h4" sx={{ fontFamily: 'Arial', fontWeight: 'bold' , fontSize:'35px'}}>
          Our youth of <span style={{ color: '#03fc24' }}>Pakistan</span> are the face of Pakistan
        </Typography>
        <Typography sx={{fontSize:'20px', fontWeight:'bold'}} className='title_nav'><b>Village Area</b></Typography>
      </div>
      )
  },
  {
    url: Slider2,
    caption: 'Slide 2',
    text: (
        <div>
        <Typography variant="h4" sx={{ fontFamily: 'Arial', fontWeight: 'bold' , fontSize:'35px'}}>
        Empowering communities by <span style={{ color: '#f7bf23' }}>providing nourishment</span> to those in need 
        </Typography>
        <Typography sx={{fontSize:'20px', fontWeight:'bold'}} className='title_nav'><b>Village Area</b></Typography>
      </div>
      )
  },
  {
    url: Food,
    caption: 'Slide 3',
    text: (
        <div>
        <Typography variant="h4" sx={{ fontFamily: 'Arial', fontWeight: 'bold' , fontSize:'35px'}}>
        Uniting hearts, <span style={{ color: '#03fc24' }}> one plate at a time</span> , with our Community Food Assistance Program
        </Typography>
        <Typography sx={{fontSize:'20px', fontWeight:'bold'}} className='title_nav'><b>Village Area</b></Typography>
      </div>
      )
  },
  {
    url: checking,
    caption: 'Slide 4',
    text: (
        <div>
        <Typography variant="h4" sx={{ fontFamily: 'Arial', fontWeight: 'bold' , fontSize:'35px'}}>
        Ensuring impeccable food quality, <span style={{ color: '#f7bf23' }}>nourishing both body and spirit</span> , for our hospital partners
        </Typography>
        <Typography sx={{fontSize:'20px', fontWeight:'bold'}} className='title_nav'><b>Village Area</b></Typography>
      </div>
      )
  },
];

const textStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: 'white',
  zIndex: 1,
  backgroundColor: 'rgba(241, 86, 42, 0.5)',
  width: '70%',
  margin: '0 auto', 
  padding: '20px' , 
  borderRadius: '30px'
  // Additional styles can be added here
};

const Slideshow = () => {
  return (
    <div className="slide-container" style={{ position: 'relative' }}>
      <Fade autoplay={true}>
        {slideImages.map((slide, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <img style={{ width: '100%', height: '600px' , borderRadius:'30px'}} src={slide.url} />
            <div style={textStyle}>
              <Typography variant="h4" sx={{ fontFamily: 'Arial', fontWeight: 'bold',  }}>{slide.text}</Typography>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
}

export default Slideshow;
